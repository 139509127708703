<template>
  <section id="contact">
      <div class="container">
        <div class="property-location mb-5">
          <div class="divider-fade"></div>
          <div id="map-contact" class="contact-map">
            <v-img
              style="cursor: pointer; object-fit: scale-down"
              @click="goMaps"
              target="_blank"
              width="100%"
              height="500px"
              class="app-badge"
              src=".././assets/mapa.png"
              alt="img maps"
            />
            <!-- <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3763.545578953509!2d-70.60914128509404!3d19.388819386909102!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8eb1d1c577cbc3a9%3A0xcd520225a9e3f72f!2sNENE%20RENT%20CAR!5e0!3m2!1ses-419!2sdo!4v1663074091068!5m2!1ses-419!2sdo"
              width="100%"
              height="100%"
              frameborder="0"
              style="border: 0"
              allowfullscreen=""
            ></iframe> -->
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4 col-md-12">
            <div class="contact-info">
              <p>
                {{ $t("information.a1") }} 
              </p>
              <p>
                <strong>    {{ $t("information.a2.correo") }}  :</strong>&nbsp;<a
                  class="link"
                  style="color: #29742d"
                  href="mailto:nenerentcar@gmail.com"
                  >nenerentcar@gmail.com</a
                ><span id="recipientOutput" class="recipientOutput"></span
                ><br />
                <strong>   {{ $t("information.a2.telefono") }} :</strong>
                <a style="color: #29742d" class="link" href="tel:8299836382"
                  >(829) 983-6382</a
                ><br />
                <strong>{{ $t("information.a2.direccion") }}:</strong>
                <a
                  class="link"
                  style="color: #29742d"
                  href="https://www.google.com/maps?q=nenerentcar&um=1&ie=UTF-8&sa=X&ved=2ahUKEwiQ87eq65H6AhW-QzABHdJZB0EQ_AUoAnoECAIQBA"
                  >{{ $t("information.a2.address") }}&nbsp;</a
                >
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-12">
            <div class="">
              <p>
                {{ $t("information.a3") }} 
              </p>
              <p>
                <strong>  {{ $t("information.a4") }} </strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
</template>

<script>
export default {
  methods: {
    goMaps() {
      window.open(
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3763.545578953509!2d-70.60914128509404!3d19.388819386909102!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8eb1d1c577cbc3a9%3A0xcd520225a9e3f72f!2sNENE%20RENT%20CAR!5e0!3m2!1ses-419!2sdo!4v1663074091068!5m2!1ses-419!2sdo",
        "_blank"
      );
    },
  },
};
</script>

<style scoped src="../style.css"></style>

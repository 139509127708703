<template>
  <section class="mt-10">
    <v-row justify="center">
      <iframe
        style="min-height: 90vh; width: 100%; position: relative"
        src="https://smartcodestorage.sfo3.digitaloceanspaces.com/nenerentcar/politicas.pdf"
        frameborder="0"
      >
      </iframe>
    </v-row>
  </section>
</template>

<script>
export default {};
</script>

<template>
  <section id="features">
    <div class="container px-5">
      <div class="row gx-5 align-items-center">
        <div class="col-lg-8 order-lg-1 mb-5 mb-lg-0">
          <div class="container-fluid px-5">
            <div class="row gx-5">
              <div class="col-md-6 mb-5">
                <!-- Feature item-->
                <div class="text-center">
                  <i
                    class="bi-calendar2-range icon-feature text-gradient d-block mb-3"
                  ></i>
                  <h3 class="font-alt"> {{ $t("about.a1.title") }}</h3>
                  <p class="text-muteds mb-0">
                 {{ $t("about.a1.subtitle") }}
                  </p>
                </div>
              </div>
              <div class="col-md-6 mb-5">
                <!-- Feature item-->
                <div class="text-center">
                  <i
                    class="bi-signpost-2 icon-feature text-gradient d-block mb-3"
                  ></i>
                  <h3 class="font-alt">{{ $t("about.a2.title") }}</h3>
                  <p class="text-muteds mb-0">
                    {{ $t("about.a2.subtitle") }}
                  </p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 mb-5 mb-md-0">
                <!-- Feature item-->
                <div class="text-center">
                  <i
                    class="bi-currency-exchange icon-feature text-gradient d-block mb-3"
                  ></i>
                  <h3 class="font-alt">{{ $t("about.a3.title") }}</h3>
                  <p class="text-muteds mb-0">
                 {{ $t("about.a3.subtitle") }}
                  </p>
                </div>
              </div>
              <div class="col-md-6">
                <!-- Feature item-->
                <div class="text-center">
                  <i
                    class="bi-mailbox icon-feature text-gradient d-block mb-3"
                  ></i>
                  <h3 class="font-alt">{{ $t("about.a4.title") }}</h3>
                  <p class="text-muteds mb-0">
                   {{ $t("about.a4.subtitle") }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 order-lg-0">
          <!-- Features section device mockup-->
          <div class="features-device-mockup">
            <svg
              class="circle"
              viewBox="0 0 100 100"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs>
                <linearGradient
                  id="circleGradient"
                  gradientTransform="rotate(45)"
                >
                  <stop class="gradient-start-color" offset="0%"></stop>
                  <stop class="gradient-end-color" offset="100%"></stop>
                </linearGradient>
              </defs>
              <circle cx="50" cy="50" r="50"></circle>
            </svg>
            <svg
              class="shape-1 d-none d-sm-block"
              viewBox="0 0 240.83 240.83"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="-32.54"
                y="78.39"
                width="305.92"
                height="84.05"
                rx="42.03"
                transform="translate(120.42 -49.88) rotate(45)"
              ></rect>
              <rect
                x="-32.54"
                y="78.39"
                width="305.92"
                height="84.05"
                rx="42.03"
                transform="translate(-49.88 120.42) rotate(-45)"
              ></rect>
            </svg>
            <svg
              class="shape-2 d-none d-sm-block"
              viewBox="0 0 100 100"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="50" cy="50" r="50"></circle>
            </svg>
            <div class="device-wrapper">
              <div
                class="device"
                data-device="iPhoneX"
                data-orientation="portrait"
                data-color="black"
              >
                <div class="screen bg-black">
                  <!-- PUT CONTENTS HERE:-->
                  <!-- * * This can be a video, image, or just about anything else.-->
                  <!-- * * Set the max width of your media to 100% and the height to-->
                  <!-- * * 100% like the demo example below.-->
                  <img
                    src=".././assets/img/preview_02.png"
                    style="max-width: 100%; height: 100%"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped src="../style.css"></style>

<template>
  <v-col
    data-aos="fade-up"
    data-aos-duration="800"
    offset-md="0"
    cols="12"
    sm="8"
    md="3"
    lg="3"
    xl="3"
  >
    <v-card color="transparent" class="pt-2 rounded-lg" elevation="0">
      <v-carousel
        :style="
          item.vehicle.isDeluxe
            ? ' ;border: 8px solid  rgb(212, 175, 55) ; 	border-radius: 0.15rem; '
            : 'color:black'
        "
        height="auto"
        hide-delimiter-background
        class="rounded-lg"
      >
        <template v-if="maintenance == false">
          <v-carousel-item
            :to="`/vehicles/${item.vehicle.rowId}`"
            v-for="(items, i) in item.vehicle.vehicleImages.nodes"
            aspect-ratio="1.0"
            :src="items.imageUrl"
            :key="i"
          >
            <template v-slot:placeholder>
              <v-sheet>
                <v-skeleton-loader
                  class="mx-auto"
                  width="auto"
                  height="auto"
                  type="image,image"
                ></v-skeleton-loader>
              </v-sheet>
            </template>
            <div
              v-if="item.vehicle.isDeluxe && maintenance == false"
              style=""
              class="pa- text-right"
            >
              <v-col cols="12" md="12">
                <v-row justify="end">
                  <div
                    class="mt-1 mr-1 text-center rounded-lg"
                    style="background-color: #d4af37"
                  >
                    <span
                      style="font-size: 12px"
                      class="font-weight-bold text-center px-1"
                    >
                      DELUXE</span
                    >
                  </div>
                </v-row>
              </v-col>
            </div>
          </v-carousel-item>
        </template>
        <template v-if="maintenance == true">
          <v-carousel-item
            v-for="(items, i) in item.vehicle.vehicleImages.nodes"
            aspect-ratio="1.0"
            :src="items.imageUrl"
            :key="i"
          >
            <template v-slot:placeholder>
              <v-sheet>
                <v-skeleton-loader
                  class="mx-auto rounded-xl"
                  width="auto"
                  height="auto"
                  type="image,image"
                ></v-skeleton-loader>
              </v-sheet>
            </template>
            <div
              v-if="item.vehicle.isDeluxe && maintenance == false"
              style=""
              class="pa-4 text-right"
            >
              <v-col cols="12" md="12">
                <v-row justify="end">
                  <div
                    class="pa-2 text-center rounded-xl"
                    style="background-color: #d4af37"
                  >
                    <span
                      style="font-size: 14px; border-radius: 0.6rem"
                      class="font-weight-bold text-center"
                    >
                      DELUXE</span
                    >
                  </div>
                </v-row>
              </v-col>
            </div>

            <div
              v-if="Authentication.user.isAdmin == true && maintenance == true"
              style=""
              class="text-right px-2 mt-n6 pt-1"
            >
              <v-col cols="12" md="12">
                <v-row justify="end">
                  <v-card
                    @click="show(item)"
                    color="primary"
                    class="mr-n6 rounded-xl"
                    height="100px"
                    width="100px"
                  >
                    <v-icon class="pt-10 mr-9" color="white" size="40px">
                      settings
                    </v-icon>
                  </v-card>
                </v-row>
              </v-col>
            </div>
          </v-carousel-item>
        </template>
      </v-carousel>

      <v-row justify="center" class="px-6 pt-4">
        <span
          :style="
            type == 1
              ? 'color: white; font-size: 18px'
              : 'color: black; font-size: 18px'
          "
          class="font-weight-bold text-center"
          >{{ item.vehicle.vehicleBrand.name }} {{ item.vehicle.vehicleModel.name }}
          {{ item.vehicle.year }}</span
        >
      </v-row>
      <v-row justify="center" class="px-6 pt-0">
        <span
          :style="
            type == 1
              ? 'color: white; font-size: 14px'
              : 'color: black; font-size: 14px'
          "
          class="font-weight-normal text-center pt-0"
        >
          Pasajeros:
          <v-icon
            :color="type == 1 ? 'white' : 'black'"
            class="mb-1 ml-2"
            size="15px"
            >person</v-icon
          >
          {{ item.personQuantity }}
        </span>
      </v-row>

      <v-row justify="center" class="px-6 pt-1 pb-4">
        
        <span
          :style="
            type == 1
              ? 'color: white; font-size: 14px'
              : 'color: black; font-size: 14px'
          "
        >
          <b> ${{ item.price }}</b> / Dia
        </span>
      </v-row>
    </v-card>
  </v-col>
</template>

<script>
export default {
  name: "itemCard",
  data() {
    return {
      isLoad: false,
      isFavorite: false,
    };
  },
  components: {},
  props: {
    item: {
      type: Object,
      required: true,
    },
    maintenance: {
      type: Boolean,
    },
    type: {
      type: Number,
    },
  },

  methods: {
    show(item) {
      this.$emit("show-edit", item);
    },
    async loaded() {
      this.isLoad = true;
    },
    next(i) {},
    load(item) {},
  },
  computed: {
    Authentication() {
      return this.$store.state.Auth;
    },
  },
};
</script>
<style scoped>
.img {
  object-fit: fill;
}
skeleton-loader-image-height {
  height: 200px;
}
.inner-img {
  transition: 0.3s;
}
.img-wrapper {
  overflow: hidden;
}

.inner-img:hover {
  transform: scale(1.1);
}
</style>

<template>
  <v-container fluid>
    <template v-if="loadData">
      <v-row justify="center" class="px-10 mt-16">
        <ItemCard
          class="px-6 "
          v-for="item in vehicles"
          :key="item.id"
          :item="item"
          :type="2"
        />
        <!-- <ItemCard2
          class="hidden-sm-and-up"
          v-for="item in vehicles"
          :key="item.id"
          :item="item"
          :elevation="1"
        /> -->
      </v-row>
    </template>
    <v-row class="mt-15" justify="center" v-else>
      <v-progress-circular
        :size="150"
        :width="7"
        color="black"
        indeterminate
      ></v-progress-circular>
    </v-row>
  </v-container>
</template>

<script>
import ItemCard from "@/components/ItemCardOffers";
import ItemCard2 from "@/components/ItemCard2";
import moment from "moment";
import { VEHICLE_OFFERS } from "../gql/queries";

export default {
  name: "my-vehicles",
  components: {
    ItemCard,
    ItemCard2,
  },
  data() {
    return {
      loadData: false,
      selectedtab: "",
      active_tab: 0,
      vehicles: [],
      filters: [],
      vehicleBrand: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      this.loadData = false;

      var data = await this.$apollo.query({
        query: VEHICLE_OFFERS,
        variables: {
          dateNow: moment().format(),
        },
      });
   //   console.log(data.data.vehiclePricePerDays.nodes);
      this.vehicles = data.data.vehiclePricePerDays.nodes;
      
    //   for(var item of data.data.vehiclePricePerDays.nodes)
    //   {
    //     console.log(item)
    //      this.vehicles.push(item.vehicle)
    //   }
      this.loadData = true;
    },
  },
};
</script>

<style></style>

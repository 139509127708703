import { render, staticRenderFns } from "./ItemCard.vue?vue&type=template&id=3e5f3be9&scoped=true&"
import script from "./ItemCard.vue?vue&type=script&lang=js&"
export * from "./ItemCard.vue?vue&type=script&lang=js&"
import style0 from "./ItemCard.vue?vue&type=style&index=0&id=3e5f3be9&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e5f3be9",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VCard,VCarousel,VCarouselItem,VCol,VIcon,VRow,VSheet,VSkeletonLoader})

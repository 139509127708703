<template>
  <v-app>
    <!-- <Navbar /> -->

    <nav
      class="navbar navbar-expand-lg navbar-light fixed-top shadow-sm"
      id="mainNav"
    >
      <div class="container px-2">
        <router-link to="/">
          <img
            src="./assets/img/home2me_logo_large.png"
            width="120"
            alt="logo"
            class="logo"
          />
        </router-link>
       
        <v-menu
          :value="shown"
          rounded="xl"
          :nudge-width="160"
          transition="scroll-y-reverse-transition"
          offset-y
          left
          nudge-left="-10"
          nudge-top="-15"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="grey lighten-2"
              outlined
              elevation="0"
              class="rounded-pill hidden-sm-and-up"
              width="auto"
              height="48"
              v-bind="attrs"
              v-on="on"
              slot="activator"
            >
              <span style="color: black; font-size: 3vw">Menu</span>
              <v-icon size="25px" color="black">mdi-menu</v-icon>
            </v-btn>
          </template>

          <v-list class="wrapper" style="offset-position: left">
            <v-list-item to="/">
              <v-list-item-title>  {{ $t("appBar.inicio") }} </v-list-item-title>
            </v-list-item>

            <v-list-item :to="{ name: 'Vehicles' }">
              <v-list-item-title> {{ $t("appBar.catalogo") }} </v-list-item-title>
            </v-list-item>

            <v-list-item :to="{ name: 'Services' }">
              <v-list-item-title> {{ $t("appBar.nuestroServicios") }}</v-list-item-title>
            </v-list-item>

            <v-list-item :to="{ name: 'NewContac' }">
              <v-list-item-title> {{ $t("appBar.contactos") }} </v-list-item-title>
            </v-list-item>
            <v-list-item :to="{ name: 'Download' }">
              <v-list-item-title> {{ $t("appBar.descargas") }} </v-list-item-title>
            </v-list-item>
            <v-list-item :to="{ name: 'RequerimentsVue' }">
              <v-list-item-title> {{ $t("fotter.a4") }} </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        
        <div class="collapse navbar-collapse" id="navbarResponsive">
          <ul class="navbar-nav ms-auto me-4 my-3 my-lg-0">
            <li class="nav-item">
              <router-link
                style="text-decoration: none; color: inherit"
                to="/catalogue"
              >
                <a class="nav-link me-lg-3">{{ $t("appBar.catalogo") }}</a></router-link
              >
            </li>
            <li class="nav-item">
              <router-link
                style="text-decoration: none; color: inherit"
                to="/services"
              >
                <a class="nav-link me-lg-3">{{ $t("appBar.nuestroServicios") }}</a>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                style="text-decoration: none; color: inherit"
                to="/contact"
              >
                <a class="nav-link me-lg-3">{{ $t("appBar.contactos") }}</a></router-link
              >
            </li>
            <li class="nav-item">
              <router-link
                style="text-decoration: none; color: inherit"
                to="/download"
              >
                <a class="nav-link me-lg-3">{{ $t("appBar.descargas") }}</a></router-link
              >
            </li>
            <li class="nav-item">
              <router-link
                style="text-decoration: none; color: inherit"
                to="/Requeriments"
              >
                <a class="nav-link me-lg-3">{{  $t("fotter.a4")  }}</a></router-link
              >
            </li>
          </ul>

          <div style="cursor: pointer;" @click="setLocale()">
    <v-badge
        color="secondary"
        overlap
        :content="$i18n.locale"
        class="pl-2 mr-4 mt-1"
      >
        <v-icon  color="dark">mdi-web</v-icon>
      </v-badge>
          </div>
         
        </div>
            <div class=" hidden-sm-and-up" style="cursor: pointer" @click="setLocale()">
            <v-badge
              color="secondary"
              overlap
              :content="$i18n.locale"
              class="pl-2 mr-4 mt-1"
            >
              <v-icon color="dark">mdi-web</v-icon>
            </v-badge>
          </div>
      </div>
    </nav>
    <v-main>
      <v-divider></v-divider>

      <v-slide-y-transition mode="out-in">
        <router-view />
      </v-slide-y-transition>
    </v-main>
    <footer class="bg-black text-center py-5">
      <div class="container px-5">
        <div class="text-white-50 small">
          <div class="mb-2">
            &copy; {{ $t("fotter.a1") }}
          </div>
          <a @click="go()">{{ $t("fotter.a2") }}</a>
          <span class="mx-1">&middot;</span>
          <a @click="go()">{{ $t("fotter.a3") }}</a> -
            <a class="mx-0"  @click="$router.push({ name: 'RequerimentsVue' })"> {{ $t("fotter.a4") }}</a>
        </div>
      </div>
    </footer>
  </v-app>
</template>

<script>
//import Navbar from "./components/My-Navbar";
import { mapActions } from "vuex";
import {
  GET_INFO_COMPANY,
  GET_VEHICLE_BRANDS_AND_MODLES,
  GET_NOTIICATIONS,
  GET_VEHICLES_FIRST,
  COUNT_NOTIFICATIONS,
  GET_REQUERIMENT_FOR_RENT,
} from "./gql/queries";
import { READ_NOTIFICAION } from "./gql/mutation";
import Login from "./components/My-Login";
import Register from "./components/My-Register";
import CompletProfile from "./components/account/Complet-Perfil";
import moment from "moment";
export default {
  name: "App",

  components: {
    //Navbar,
    Login,
    Register,
    CompletProfile,
  },
  data() {
    return {
      type: 1,
      apilink: "",
      newapilink: "",
      phone: "+18294496091",
      menuItems: [],
      chatPhone: "",
      icons: ["facebook", "whatsapp", "mdi-instagram"],
      search: "",
      vehicles: [],
      resultVehicles: [],
      vehicleBrands: [],
      vehicleModels: [],
      notifications: [],
      counNotifications: [],
      allArticles: [],
      supcriptions: [],
      doLogin: true,
      loadedDate: false,
      showLoginForm: false,
      showRegisterForm: false,
      shown: false,
      showRegister: false,
      showCompleteProflie: false,
    };
  },
  computed: {
    url() {
      localStorage.setItem("apilinews", this.apilink);

      return this.apilink + " Hola!";
    },
    Authentication() {
      return this.$store.state.Auth;
    },
    menu() {
      var menuItems = [
        {
          icon: "push_pin",
          title: "Vehiculos",
          path: "service",
        },
        {
          icon: "push_pin",
          title: "Nuestros servicios",
          path: "service",
        },
        {
          icon: "directions_car",
          title: "Contacto",
          path: "contact",
        },
        {
          icon: "link",
          title: "Descargar",
          path: "download",
        },
      ];
      return menuItems;
    },
  },
  created() {
    this.getData();
  },

  methods: {
    async getData() {
      var dataVehicleBrandsAndModels = await this.$apollo.query({
        query: GET_VEHICLE_BRANDS_AND_MODLES,
        variables: {
          companyId: this.$companie_id,
        },
      });

      var dataCompany = await this.$apollo.query({
        query: GET_INFO_COMPANY,
        fetchPolicy: "no-cache",
      });

      this.$company_phone = dataCompany.data.company.chatPhoneNumber;
      this.$email = dataCompany.data.company.email;

      localStorage.setItem("email", dataCompany.data.company.email);
      localStorage.setItem("emailSend", dataCompany.data.company.mailUser);
      localStorage.setItem("phone", dataCompany.data.company.chatPhoneNumber);
      localStorage.setItem("logo", dataCompany.data.company.logoUrl);
      this.chatPhone = dataCompany.data.company.chatPhoneNumber;
      for (var item of dataVehicleBrandsAndModels.data.publishedBrands.nodes) {
        this.vehicleBrands.push({
          text: item.name,
          value: item.rowId,
          logoUrl: item.logoUrl,
        });
      }

      for (var item2 of dataVehicleBrandsAndModels.data.publishedModels.nodes) {
        this.vehicleModels.push({
          text: item2.name,
          value: item2.rowId,
          condicion: item2.vehicleBrandId,
        });
      }

      window.localStorage.setItem(
        "VEHICLE_BRANDS",
        JSON.stringify(this.vehicleBrands)
      );
      window.localStorage.setItem(
        "VEHICLE_MODELS",
        JSON.stringify(this.vehicleModels)
      );

      // if (this.$store.state.Auth.authStatus == true) {
      //   this.getNotifications();
      //   var supcriptions = await this.$apollo.query({
      //     query: GET_REQUERIMENT_FOR_RENT,

      //     variables: {
      //       companyId: this.$companie_id,
      //       userId: this.$store.state.Auth.user.rowId,
      //       upToDate: moment().format("YYYY-MM-DD").toString(),
      //     },
      //     fetchPolicy: "no-cache",
      //   });

      //   if (supcriptions.data.userWithSubscriptions.nodes.length > 0) {
      //     this.supcriptions =
      //       supcriptions.data.userWithSubscriptions.nodes[0].subscription;
      //   }
      // }
      this.vehicles = [];
      var data = await this.$apollo.query({
        query: GET_VEHICLES_FIRST,
        variables: { companyId: this.$companie_id, branchIds: this.$branch_id },
        fetchPolicy: "no-cache",
      });

      var datos = data.data.vehicles.nodes;

      for (var a = 0; a < datos.length; a++) {
        this.vehicles.push({
          name: datos[a].vehicleBrand.name + " " + datos[a].vehicleModel.name,
          rowId: datos[a].rowId,
          isDeluxe: datos[a].isDeluxe,
          basePrice: datos[a].basePrice,
          depositPrice: datos[a].depositPrice,
          tractionControlType: datos[a].tractionControlType,
          vehicleAmenities: datos[a].vehicleAmenities,
          vehicleColor: datos[a].vehicleColor,
          vehicleNotAvailableDates: datos[a].vehicleNotAvailableDates,
          vehicleSeating: datos[a].vehicleSeating,
          branch: datos[a].branch,
          currency: datos[a].currency,
          vehicleImages: datos[a].vehicleImages,
          vehicleBrand: datos[a].vehicleBrand,
          vehicleModel: datos[a].vehicleModel,
          year: datos[a].year,
        });
      }
      this.whatsapp();
      this.$i18n.locale = "en";
    },
    setLocale() {
      var locale = "";
      if (this.$i18n.locale == "es") {
        locale = "en";
      }

      if (this.$i18n.locale == "en") {
        locale = "es";
      }

      this.$i18n.locale = locale;
      localStorage.setItem("locale", "es");
    },
    viewVehicle(item) {
      this.search = "";
      this.$router.replace({ path: "/vehicles/" + item.rowId });
    },
    go() {
      this.$router.push({ name: "Privacy" });
    },
    seacrhVehicles(event) {
      this.resultVehicles = [];
      if (this.search.length > 0) {
        this.resultVehicles = [];

        var result = this.vehicles.find(
          (element) => element.name === event.target.value
        );
        let resultados = [];
        this.vehicles.forEach((j) => {
          let palabra = j.name.toLowerCase();

          if (palabra.includes(event.target.value.toLowerCase())) {
            resultados.push(j);
          }
        });
        this.resultVehicles = resultados;
      }
    },
    async readNotification(item) {
      try {
        var data = await this.$apollo.mutate({
          mutation: READ_NOTIFICAION,
          variables: {
            notificationId: item.rowId,
          },
        });
      } catch (err) {
        this.$toast.error("A ocurrido un error " + err);
      } finally {
        this.getNotifications();
      }
    },
    // go() {
    //   this.$router.push({ name: "Notifications" });
    // },
    goRoute(item) {
      this.$router.push({ name: item.path });
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    whatsapp() {
      this.apilink = "http://";
      this.apilink += this.isMobile() ? "api" : "web";
      this.newapilink =
        this.apilink + ".whatsapp.com/send?phone=" + this.chatPhone + "&text=";
      this.apilink += ".whatsapp.com/send?phone=" + this.chatPhone + "&text=";

      localStorage.setItem("apilinews", this.apilink);

      this.$newapilinks = this.apilink;
    },
    ...mapActions({
      logOut: "Auth/logOut",
    }),
    logout() {
      this.logOut();
    },
    getYears(year) {
      moment.locale("es");
      return moment(year).fromNow();
    },
    showData(item) {
      return moment(item).format("YYYY-MM-DD - hh:mm:ss A");
    },

    // async getNotifications() {
    //   // this.loadingNotifications=false
    //   var notification = await this.$apollo.query({
    //     query: GET_NOTIICATIONS,
    //     variables: { userId: this.$store.state.Auth.user.rowId },
    //     fetchPolicy: "no-cache",
    //   });

    //   this.notifications = notification.data.notifications.nodes;

    //   this.loadedDate = true;
    //   var cNotifications = await this.$apollo.query({
    //     query: COUNT_NOTIFICATIONS,
    //     variables: { userId: this.$store.state.Auth.user.rowId },
    //     fetchPolicy: "no-cache",
    //   });

    //   this.counNotifications = cNotifications.data.notifications.totalCount;
    // },
  },
};
</script>
<style scoped src="./style.css">
.my-application {
  font-family: Cereal;
}

@font-face {
  font-family: "Cerealbold";
  src: local("Cerealbold"),
    url(./fonts/AirbnbCereal_W_XBd.otf) format("truetype");
}
</style>

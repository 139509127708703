<template>
  <div>
    <header style="margin-top: -50px" class="masthead">
      <div class="container px-5">
        <div class="row gx-5 align-items-center">
          <div class="col-lg-6">
            <!-- Mashead text and app badges-->
            <div class="mb-5 mb-lg-0 text-center text-lg-start">
              <h1 class="displays-1 lh-1 mb-3">{{ $t("home.a1") }}</h1>
              <p class="lead fw-normal text-muteds mb-5">
                {{ $t("home.a2") }}
              </p>

              <!-- <v-row dense justify="center">
                <v-col cols="6">

                </v-col>
                <v-col cols="6">

                </v-col>
                <div class="col">
                  <a
                    target="_blank"
                    href="https://play.google.com/store/apps/details?id=com.smartcode.rentcar"
                    ><img
                      class="app-badge"
                      src=".././assets/img/google-play-badge.svg"
                      alt="..."
                  /></a>
                  <a
                    target="_blank"
                    href="https://apps.apple.com/us/app/nene-rent-a-car/id1645147496"
                    ><img
                      class="app-badge"
                      src=".././assets/img/app-store-badge.svg"
                      alt="..."
                  /></a>
                </div>
              </v-row> -->
              <v-row class="hidden-sm-and-up" dense justify="center">
                <v-col cols="6" xs="6" md="6" align="end">
                  <a
                    target="_blank"
                    href="https://play.google.com/store/apps/details?id=com.smartcode.rentcar"
                    ><v-img
                      contain
                      class="app-badge"
                      src=".././assets/img/google-play-badge.svg"
                      alt="..."
                  /></a>
                </v-col>
                <v-col cols="6" xs="6" md="6" align="start">
                  <a
                    target="_blank"
                    href="https://apps.apple.com/us/app/nene-rent-a-car/id1645147496"
                    ><v-img
                      contain
                      class="app-badge"
                      src=".././assets/img/app-store-badge.svg"
                      alt="apple"
                  /></a>
                </v-col>
              </v-row>
              <v-row class="hidden-sm-and-down">
                <div class="d-flex flex-row flex-lg-row align-items-center">
                  <a
                    target="_blank"
                    href="https://play.google.com/store/apps/details?id=com.smartcode.rentcar"
                    ><img
                      class="app-badge"
                      src=".././assets/img/google-play-badge.svg"
                      alt="android"
                  /></a>
                  <a
                    target="_blank"
                    href="https://apps.apple.com/us/app/nene-rent-a-car/id1645147496"
                    ><img
                      class="app-badge"
                      src=".././assets/img/app-store-badge.svg"
                      alt="..."
                  /></a>
                </div>
              </v-row>
            </div>
          </div>
          <div class="col-lg-6">
            <!-- Masthead device mockup feature-->
            <div class="masthead-device-mockup">
              <svg
                class="circle"
                viewBox="0 0 100 100"
                xmlns="http://www.w3.org/2000/svg"
              >
                <defs>
                  <linearGradient
                    id="circleGradient"
                    gradientTransform="rotate(45)"
                  >
                    <stop class="gradient-start-color" offset="0%"></stop>
                    <stop class="gradient-end-color" offset="100%"></stop>
                  </linearGradient>
                </defs>
                <circle cx="50" cy="50" r="50"></circle>
              </svg>

              <svg
                class="shape-1 d-none d-sm-block"
                viewBox="0 0 240.83 240.83"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="-32.54"
                  y="78.39"
                  width="305.92"
                  height="84.05"
                  rx="42.03"
                  transform="translate(120.42 -49.88) rotate(45)"
                ></rect>
                <rect
                  x="-32.54"
                  y="78.39"
                  width="305.92"
                  height="84.05"
                  rx="42.03"
                  transform="translate(-49.88 120.42) rotate(-45)"
                ></rect>
              </svg>

              <svg
                class="shape-2 d-none d-sm-block"
                viewBox="0 0 100 100"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="50" cy="50" r="50"></circle>
              </svg>
              <div class="device-wrapper">
                <div
                  class="device"
                  data-device="iPhoneX"
                  data-orientation="portrait"
                  data-color="black"
                >
                  <div class="screen bg-black">
                    <!-- PUT CONTENTS HERE:-->
                    <!-- * * This can be a video, image, or just about anything else.-->
                    <!-- * * Set the max width of your media to 100% and the height to-->
                    <!-- * * 100% like the demo example below.-->
                    <img
                      src=".././assets/img/preview_01.png"
                      style="max-width: 100%; height: 100%"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>

    <section class="cta" id="download">
      <v-container>
        <v-row justify="center">
          <v-col cols="12">
            <v-row justify="center">
              <v-col cols="12" md="12">
                <h1
                  style="font-size: 1em"
                  class="my-application hidden-sm-and-down white--text pa-4"
                >
                 {{ $t("catalog.a1") }}
                  <v-btn
                    @click="go()"
                    outlined
                    color="white"
                    class="float-right"
                    ><v-icon>menu</v-icon>   {{ $t("catalog.a2") }}</v-btn
                  >
                </h1>

                <span
                  style="font-size: 1em"
                  class="my-application hidden-sm-and-up white--text pa-4"
                >
                   {{ $t("catalog.a1") }}
                  <v-btn
                    @click="go()"
                    outlined
                    color="white"
                    class="float-right"
                    ><v-icon>menu</v-icon>
                  </v-btn>
                </span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row justify="center">
          <ItemCard
            class="px-6 hidden-sm-and-down"
            v-for="item in vehicles"
            :key="item.id"
            :item="item"
            :type="1"
          />
          <ItemCard2
            class="hidden-sm-and-up"
            v-for="item in vehicles"
            :key="item.id"
            :item="item"
            :elevation="0"
          />
        </v-row>
        <v-row class="mt-4 mb-1 hidden-sm-and-up" justify="center">
          <v-col cols="4">
            <v-btn @click="go()" outlined color="white">   {{ $t("catalog.a3") }}</v-btn>
          </v-col>
        </v-row>

        <v-row class="mt-2 mb-1 hidden-sm-and-down" justify="center">
          <v-col cols="1">
            <v-btn @click="go()" outlined color="white">   {{ $t("catalog.a3") }}</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <!-- App features section-->
   <section id="features">
    <div class="container px-5">
      <div class="row gx-5 align-items-center">
        <div class="col-lg-8 order-lg-1 mb-5 mb-lg-0">
          <div class="container-fluid px-5">
            <div class="row gx-5">
              <div class="col-md-6 mb-5">
                <!-- Feature item-->
                <div    style="cursor: pointer"
                    class="text-center"
                    @click="$router.push({ name: 'Vehicles' })">
                  <i
                    class="bi-calendar2-range icon-feature text-gradient d-block mb-3"
                  ></i>
                  <h3 class="font-alt"> {{ $t("about.a1.title") }}</h3>
                  <p class="text-muteds mb-0">
                 {{ $t("about.a1.subtitle") }}
                  </p>
                </div>
              </div>
              <div             class="col-md-6 mb-5"
                  style="cursor: pointer"
                  @click="$router.push({ name: 'Vehicles' })">
                <!-- Feature item-->
                <div class="text-center">
                  <i
                    class="bi-signpost-2 icon-feature text-gradient d-block mb-3"
                  ></i>
                  <h3 class="font-alt">{{ $t("about.a2.title") }}</h3>
                  <p class="text-muteds mb-0">
                    {{ $t("about.a2.subtitle") }}
                  </p>
                </div>
              </div>
            </div>
            <div class="row">
              <div     class="col-md-6 mb-5 mb-md-0"
                  style="cursor: pointer"
                  @click="$router.push({ name: 'OffersVue' })">
                <!-- Feature item-->
                <div class="text-center">
                  <i
                    class="bi-currency-exchange icon-feature text-gradient d-block mb-3"
                  ></i>
                  <h3 class="font-alt">{{ $t("about.a3.title") }}</h3>
                  <p class="text-muteds mb-0">
                 {{ $t("about.a3.subtitle") }}
                  </p>
                </div>
              </div>
              <div class="col-md-6">
                <!-- Feature item-->
                <div   style="cursor: pointer"
                    class="text-center"
                    @click="$router.push({ name: 'Airports' })">
                  <i
                    class="bi-mailbox icon-feature text-gradient d-block mb-3"
                  ></i>
                  <h3 class="font-alt">{{ $t("about.a4.title") }}</h3>
                  <p class="text-muteds mb-0">
                   {{ $t("about.a4.subtitle") }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 order-lg-0">
          <!-- Features section device mockup-->
          <div class="features-device-mockup">
            <svg
              class="circle"
              viewBox="0 0 100 100"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs>
                <linearGradient
                  id="circleGradient"
                  gradientTransform="rotate(45)"
                >
                  <stop class="gradient-start-color" offset="0%"></stop>
                  <stop class="gradient-end-color" offset="100%"></stop>
                </linearGradient>
              </defs>
              <circle cx="50" cy="50" r="50"></circle>
            </svg>
            <svg
              class="shape-1 d-none d-sm-block"
              viewBox="0 0 240.83 240.83"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="-32.54"
                y="78.39"
                width="305.92"
                height="84.05"
                rx="42.03"
                transform="translate(120.42 -49.88) rotate(45)"
              ></rect>
              <rect
                x="-32.54"
                y="78.39"
                width="305.92"
                height="84.05"
                rx="42.03"
                transform="translate(-49.88 120.42) rotate(-45)"
              ></rect>
            </svg>
            <svg
              class="shape-2 d-none d-sm-block"
              viewBox="0 0 100 100"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="50" cy="50" r="50"></circle>
            </svg>
            <div class="device-wrapper">
              <div
                class="device"
                data-device="iPhoneX"
                data-orientation="portrait"
                data-color="black"
              >
                <div class="screen bg-black">
                  <!-- PUT CONTENTS HERE:-->
                  <!-- * * This can be a video, image, or just about anything else.-->
                  <!-- * * Set the max width of your media to 100% and the height to-->
                  <!-- * * 100% like the demo example below.-->
                  <img
                    src=".././assets/img/preview_02.png"
                    style="max-width: 100%; height: 100%"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
    <aside class="text-center bg-gradient-primary-to-secondary">
      <div class="container px-5">
        <div class="row gx-5 justify-content-center">
          <div class="col-12 col-lg-5">
            <h2 style="color: white;" class="text-center  font-alt mb-4">
              {{ $t("notas.title") }}
            </h2>
            <p style="color: white;"  class="lead fw-normal mb-5 mb-lg-0">
               {{ $t("notas.subtitle") }}
            </p>
          </div>
        </div>
      </div>
    </aside>
    <!-- Basic features section-->
    <section class="bg-light">
      <div class="container px-5">
        <div
          class="row gx-5 align-items-center justify-content-center justify-content-lg-between"
        >
          <div class="col-12 col-lg-5">
            <h2 class="displays-4 lh-1 mb-4"> {{ $t("busqueda.title") }} </h2>
            <p class="lead fw-normal text-muteds mb-5 mb-lg-0">
               {{ $t("busqueda.subtitle") }} 
            </p>
          </div>
          <div class="col-lg-4 order-lg-0">
            <!-- Features section device mockup-->
            <div class="features-device-mockup">
              <svg
                class="circle"
                viewBox="0 0 100 100"
                xmlns="http://www.w3.org/2000/svg"
              >
                <defs>
                  <linearGradient
                    id="circleGradient"
                    gradientTransform="rotate(45)"
                  >
                    <stop class="gradient-start-color" offset="0%"></stop>
                    <stop class="gradient-end-color" offset="100%"></stop>
                  </linearGradient>
                </defs>
                <circle cx="50" cy="50" r="50"></circle></svg
              ><svg
                class="shape-1 d-none d-sm-block"
                viewBox="0 0 240.83 240.83"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="-32.54"
                  y="78.39"
                  width="305.92"
                  height="84.05"
                  rx="42.03"
                  transform="translate(120.42 -49.88) rotate(45)"
                ></rect>
                <rect
                  x="-32.54"
                  y="78.39"
                  width="305.92"
                  height="84.05"
                  rx="42.03"
                  transform="translate(-49.88 120.42) rotate(-45)"
                ></rect></svg
              ><svg
                class="shape-2 d-none d-sm-block"
                viewBox="0 0 100 100"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="50" cy="50" r="50"></circle>
              </svg>
              <div class="device-wrapper">
                <div
                  class="device"
                  data-device="iPhoneX"
                  data-orientation="portrait"
                  data-color="black"
                >
                  <div class="screen bg-black">
                    <!-- PUT CONTENTS HERE:-->
                    <!-- * * This can be a video, image, or just about anything else.-->
                    <!-- * * Set the max width of your media to 100% and the height to-->
                    <!-- * * 100% like the demo example below.-->
                    <img
                      src=".././assets/img/preview_03.png"
                      style="max-width: 100%; height: 100%"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Call to action section-->
    <section id="contact">
      <div class="container">
        <div class="property-location mb-5">
          <div class="divider-fade"></div>
          <div id="map-contact" class="contact-map">
            <v-img
              style="cursor: pointer; object-fit: scale-down"
              @click="goMaps"
              target="_blank"
              width="100%"
              height="500px"
              class="app-badge"
              src=".././assets/mapa.png"
              alt="..."
            />
            <!-- <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3763.545578953509!2d-70.60914128509404!3d19.388819386909102!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8eb1d1c577cbc3a9%3A0xcd520225a9e3f72f!2sNENE%20RENT%20CAR!5e0!3m2!1ses-419!2sdo!4v1663074091068!5m2!1ses-419!2sdo"
              width="100%"
              height="100%"
              frameborder="0"
              style="border: 0"
              allowfullscreen=""
            ></iframe> -->
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4 col-md-12">
            <div class="contact-info">
              <p>
                {{ $t("information.a1") }} 
              </p>
              <p>
                <strong>    {{ $t("information.a2.correo") }}  :</strong>&nbsp;<a
                  class="link"
                  style="color: #29742d"
                  href="mailto:nenerentcar@gmail.com"
                  >nenerentcar@gmail.com</a
                ><span id="recipientOutput" class="recipientOutput"></span
                ><br />
                <strong>   {{ $t("information.a2.telefono") }} :</strong>
                <a style="color: #29742d" class="link" href="tel:8299836382"
                  >(829) 983-6382</a
                ><br />
                <strong>{{ $t("information.a2.direccion") }}:</strong>
                <a
                  class="link"
                  style="color: #29742d"
                  href="https://www.google.com/maps?q=nenerentcar&um=1&ie=UTF-8&sa=X&ved=2ahUKEwiQ87eq65H6AhW-QzABHdJZB0EQ_AUoAnoECAIQBA"
                  >{{ $t("information.a2.address") }}&nbsp;</a
                >
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-12">
            <div class="">
              <p>
                {{ $t("information.a3") }} 
              </p>
              <p>
                <strong>  {{ $t("information.a4") }} </strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- App badge section-->
  </div>
</template>
<script>
import ItemCard from "@/components/ItemCard";
import ItemCard2 from "@/components/ItemCard2";
import { GET_VEHICLES_POPULARS, GET_SUPCRIPTIONS } from "../gql/queries";
const json = require("../data/vehciles.json");
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Home",
  components: {
    ItemCard,
    ItemCard2,
  },
  data() {
    return {
      loading: false,
      vehicles: [],
      vehiclesBrandes: [],
      vehicleModels: [],
      paginado: json,
      supcriptions: [],
    };
  },

  created() {
    this.getData();
  },
  computed: {
    articles() {
      return articles;
    },
  },
  methods: {
    goMaps() {
      window.open(
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3763.545578953509!2d-70.60914128509404!3d19.388819386909102!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8eb1d1c577cbc3a9%3A0xcd520225a9e3f72f!2sNENE%20RENT%20CAR!5e0!3m2!1ses-419!2sdo!4v1663074091068!5m2!1ses-419!2sdo",
        "_blank"
      );
    },
    go() {
      this.$router.push({ name: "Vehicles" });
    },
    setLocale(locale) {
      this.$i18n.locale = locale;
      localStorage.setItem("locale", locale);
    },
    async getData() {
      this.loading = false;
      this.vehiclesBrandes = JSON.parse(localStorage.getItem("VEHICLE_BRANDS"));
      this.vehicleModels = JSON.parse(localStorage.getItem("VEHICLE_MODELS"));
      var data2 = await this.$apollo.query({
        query: GET_SUPCRIPTIONS,
        variables: { companyId: this.$companie_id },
      });

      this.supcriptions = data2.data.subscriptions.nodes;
      var data = await this.$apollo.query({
        query: GET_VEHICLES_POPULARS,
        variables: { companyId: this.$companie_id, branchIds: this.$branch_id },
      });

      var datos = data.data.vehicles.nodes;
      var veh = [];
      for (var a = 0; a < datos.length; a++) {
        veh.push(datos[a]);

        // for (var items of datos[a].vehicles.nodes) {
        //   this.vehicles.push({
        //     rowId: items.rowId,
        //     isDeluxe: items.isDeluxe,
        //     basePrice: items.basePrice,

        //     tractionControlType: items.tractionControlType,
        //     vehicleAmenities: items.vehicleAmenities,
        //     vehicleColor: items.vehicleColor,
        //     vehicleNotAvailableDates: items.vehicleNotAvailableDates,
        //     vehicleSeating: items.vehicleSeating,
        //     branch: items.branch,
        //     currency: items.currency,
        //     vehicleImages: items.vehicleImages,
        //     vehicleBrand: items.vehicleBrand,
        //     vehicleModel: items.vehicleModel,
        //     year: items.year,
        //   });
        // }
      }
      var i = 0;
      var index = 0;
      for (var item of veh) {
        if (i < 8) {
          index = Math.floor(Math.random() * veh.length);

          for (var a = 0; a <= veh.length; a++) {
            if (index == a) {
              this.vehicles.push(veh[a]);
            }
          }
        }
        i++;
      }
      this.loading = true;
    },
    async searchVehicles() {},
  },
};
</script>
<style scoped src="../style.css"></style>

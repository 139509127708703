<template>
  <section class="mt-10">
    <v-row justify="center">
      <v-col cols="8">
        <v-row justify="center">
          <span class="text-center" style="font-size: 2rem">
            {{ $t("requeriments.title") }}
          </span>
        </v-row>

        <v-row>
          <span style="font-size: 1.3rem"> {{ $t("requeriments.a1") }}</span>
        </v-row>
        <v-row>
          <span style="font-size: 1.3rem"> {{ $t("requeriments.a2") }}</span>
        </v-row>
        <v-row class="mt-8">
          <span style="font-size: 1.3rem">
             {{ $t("requeriments.a3") }}</span
          >
        </v-row>
        <v-row class="mt-8">
          <span style="font-size: 1.3rem">
            {{ $t("requeriments.a4") }}</span
          >
        </v-row>
        <v-row class="mt-8">
          <span style="font-size: 1.3rem"
            > {{ $t("requeriments.a5") }}</span
          >
        </v-row>
        <v-row class="mt-4">
                <p>
                <strong>    {{ $t("information.a2.correo") }}  :</strong>&nbsp;<a
                  class="link"
                  style="color: #29742d"
                  href="mailto:nenerentcar@gmail.com"
                  >nenerentcar@gmail.com</a
                ><span id="recipientOutput" class="recipientOutput"></span
                ><br />
                <strong>   {{ $t("information.a2.telefono") }} :</strong>
                <a style="color: #29742d" class="link" href="tel:8299836382"
                  >(829) 983-6382</a
                ><br />
                <strong>{{ $t("information.a2.direccion") }}:</strong>
                <a
                  class="link"
                  style="color: #29742d"
                  href="https://www.google.com/maps?q=nenerentcar&um=1&ie=UTF-8&sa=X&ved=2ahUKEwiQ87eq65H6AhW-QzABHdJZB0EQ_AUoAnoECAIQBA"
                  >{{ $t("information.a2.address") }}&nbsp;</a
                >
              </p>
        </v-row>
      </v-col>
    </v-row>
  </section>
</template>

<script>
export default {};
</script>

<template>
  <v-container fluid>
    <Aeropuerto class="mt-16" :airports="airports" />
    <Provincias :provincies="provincies" />
  </v-container>
</template>

<script>
import { GET_MATEINANCES } from "@/gql/queries";
import Aeropuerto from "../components/maintenance/Aeropuerto.vue";
import Provincias from "../components/maintenance/Provincias.vue";
export default {
  data() {
    return {
      dialogSeguros: false,
      dialogProvincias: false,
      dialogAeropuertos: false,
      dialogSucursales: false,
      dialogSegurosAdicionales: false,
      dialogSuscripcion: false,
      dialgoItems: false,
      insurances: [],
      provincies: [],
      airports: [],
      branches: [],
      newItems: [],
      additionalCoverages: [],
      suscriptions: [],
      items: [
        {
          icon: "sell",
          name: "Suscripciones",
          description: "Gestiona las suscripciones disponibles.",
          dialog: "Suscripciones",
        },
        {
          icon: "mdi-shield-car",
          name: "Seguros",
          description: "Gestiona los seguros disponibles.",
          dialog: "Seguros",
        },

        {
          icon: "location_on",
          name: "Provincias",
          description: "Gestiona los provincias disponibles.",
          dialog: "Provincias",
        },
        {
          icon: "flight",
          name: "Aeropuertos",
          description: "Gestiona los aeropuesrtos disponibles.",
          dialog: "Aeropuertos",
        },

        {
          icon: "home",
          name: "Sucursales",
          description: "Gestiona tu sucursales.",
          dialog: "Sucursales",
        },

        {
          icon: "mdi-shield-car",
          name: "Seguros adicionales",
          description: "Gestiona tu seguros disponibles.",
          dialog: "SegurosAdicionales",
        },

        {
          icon: "add",
          name: "Acesorios",
          description: "Gestiona tu acesorios.",
          dialog: "Acesorios",
        },
      ],
    };
  },
  components: { Aeropuerto, Provincias },
  created() {
    this.getItems();
  },
  methods: {
    async getItems() {
      this.loading = false;
      const data = await this.$apollo.query({
        query: GET_MATEINANCES,
        variables: { companyId: this.$companie_id },
        fetchPolicy: "no-cache",
      });
      this.insurances = data.data.insurances.nodes;
      this.suscriptions = data.data.subscriptions.nodes;
      this.newItems = data.data.rentalItems.nodes;
      this.provincies = data.data.provinceInCompanies.nodes;
      this.airports = data.data.airports.nodes;
      this.branches = data.data.branches.nodes;
      this.additionalCoverages = data.data.additionalCoverages.edges;
      this.loading = true;
    },
  },
};
</script>

<style></style>
